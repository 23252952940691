<template>
    <v-container class="amp-module-page">
        <a-form
            ref="form"
            :auto-submit="!isModuleBusy && !callingGenerativeApi"
            @auto-submit="autoSave"
        >
            <!-- Publish Issues -->
            <v-row v-if="publishIssues.length">
                <v-col cols="12" md="8">
                    <v-container>
                        <a-alert
                            v-for="(issue, i) in publishIssues"
                            :key="i"
                            :message="issue.message"
                            class="publish-issues-alert"
                        />
                    </v-container>
                </v-col>
            </v-row>

            <template v-if="isLongFormEditor && contentSources.length">
                <amp-expansion-panel
                    key="content-sources-expansion-panel"
                    :expand="showContentSources"
                    label="Background Info"
                    :disabled="callingGenerativeApi"
                    @update:expand="showContentSources = $event"
                >
                    <!-- Headline -->
                    <amp-row>
                        <template #input>
                            <div class="py-4">
                                <a-text-input
                                    ref="headline"
                                    v-model="blogpost.headline"
                                    label="Headline"
                                    name="headline"
                                    rules="required|max:80|min:10"
                                    rows="1"
                                    textarea
                                    auto-grow
                                    observed
                                    hint="Choose a headline for blogs that also publish your amp. This should not be the same as the news article headline."
                                />
                            </div>
                        </template>
                        <template #validation>
                            <amp-validation :input="$refs.headline">
                                <a-alert
                                    :type="
                                        blogpost.headline ? 'success' : 'info'
                                    "
                                >
                                    <span>
                                        Headline must be between 10 and 80
                                        characters. Current:
                                        {{
                                            blogpost.headline
                                                ? characterCount(
                                                      blogpost.headline
                                                  )
                                                : 0
                                        }}
                                    </span>
                                </a-alert>

                                <validate-similarity
                                    :source="blogpost.headline"
                                    :target="newsArticleHeadline"
                                    message="Headline should be different from the News Article headline"
                                />
                            </amp-validation>
                        </template>
                    </amp-row>
                    <!-- Content Sources -->
                    <amp-row>
                        <!-- ContentSources -->
                        <template #input>
                            <div class="pb-4">
                                <a-text-input
                                    v-model="contentSources[0].url"
                                    rules="required|url"
                                    label="Webpage with Background info"
                                />
                                <a-text-input
                                    v-model="contentSources[1].url"
                                    :rules="{
                                        required: true,
                                        url: true,
                                        not_equals: contentSources[0].url ?? ''
                                    }"
                                    :custom-messages="{
                                        not_equals:
                                            'Please provide a different URL for the additional webpage'
                                    }"
                                    label="Additional Webpage with Background info"
                                />
                                <div
                                    :class="[
                                        'd-flex',
                                        !showRegularEditor &&
                                        !keepRegularEditorOpen
                                            ? 'justify-space-between'
                                            : 'justify-end'
                                    ]"
                                >
                                    <v-btn
                                        v-show="
                                            !showRegularEditor &&
                                            !keepRegularEditorOpen
                                        "
                                        @click="keepRegularEditorOpen = true"
                                    >
                                        Write From Scratch
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        @click="confirmGenerateOutline"
                                    >
                                        Generate Outline
                                    </v-btn>
                                </div>
                            </div>
                        </template>

                        <!-- ContentSources Validation -->
                        <template #validation>
                            <a-alert
                                type="info"
                                data-type="toggleable-info"
                                data-alert="alert-url"
                            >
                                <p class="mb-0">
                                    Provide a links to your website, product,
                                    service or business. The more links you
                                    provide the more information we can gather.
                                </p>
                            </a-alert>
                            <a-alert
                                v-if="
                                    containsLinks &&
                                    !allLinksReachable &&
                                    $vuetify.breakpoint.mdAndDown
                                "
                                type="warning"
                                data-type="warning"
                            >
                                <p class="mb-0">
                                    One or more of the links provided could not
                                    be reached. Please ensure that all links
                                    provided are accessible.
                                </p>
                            </a-alert>
                        </template>
                    </amp-row>
                </amp-expansion-panel>

                <amp-expansion-panel
                    v-if="shouldShowOutlineEditor"
                    key="outline-expansion-panel"
                    :expand="showOutline && !callingGenerativeApi"
                    label="Outline"
                    :disabled="callingGenerativeApi"
                    @update:expand="showOutline = $event"
                >
                    <amp-row>
                        <!-- Outline Editor -->
                        <template #input>
                            <a-outline-editor
                                v-model="blogpost.outline"
                                :loading="callingGenerativeApi"
                                :rules="{
                                    length: 1,
                                    max: 20,
                                    outline: true
                                }"
                            />
                            <div class="d-flex justify-end mt-4 mb-4">
                                <v-btn
                                    color="primary"
                                    @click="confirmGenerateContent"
                                >
                                    Generate Content
                                </v-btn>
                            </div>
                        </template>
                    </amp-row>
                </amp-expansion-panel>
            </template>

            <!-- Loading Indicator -->
            <amp-loading-card
                v-show="callingGenerativeApi"
                :label="generatingString"
            />

            <!-- Regular Editor -->
            <template
                v-if="
                    !callingGenerativeApi &&
                    (showRegularEditor || keepRegularEditorOpen)
                "
            >
                <amp-row-first />
                <!-- Headline -->
                <amp-row>
                    <template #input>
                        <a-text-input
                            ref="headline"
                            v-model="blogpost.headline"
                            label="Headline"
                            rules="required|max:80|min:10"
                            rows="1"
                            textarea
                            auto-grow
                            observed
                            hint="Choose a headline for blogs that also publish your amp. This should not be the same as the news article headline."
                        />
                    </template>

                    <template #validation>
                        <amp-validation :input="$refs.headline">
                            <a-alert
                                :type="blogpost.headline ? 'success' : 'info'"
                            >
                                <span>
                                    Headline must be fewer than 80 characters.
                                    Current:
                                    {{
                                        blogpost.headline
                                            ? characterCount(blogpost.headline)
                                            : 0
                                    }}
                                </span>
                            </a-alert>

                            <validate-similarity
                                :source="blogpost.headline"
                                :target="newsArticleHeadline"
                                message="Headline should be different from the News Article headline"
                            />
                        </amp-validation>
                    </template>
                </amp-row>

                <!-- Summary -->
                <amp-row>
                    <template #input>
                        <a-text-input
                            ref="summary"
                            v-model="blogpost.summary"
                            label="Summary"
                            rules="required|words:0,45"
                            textarea
                            rows="4"
                            auto-grow
                            observed
                            hint="Choose a summary for use on blogs that publish your amp. This must not be the same as the news article summary."
                        />
                    </template>

                    <template #validation>
                        <amp-validation :input="$refs.summary">
                            <a-alert
                                :type="blogpost.summary ? 'success' : 'info'"
                                :message="
                                    actualWordsCount(
                                        blogpost.summary,
                                        0,
                                        45,
                                        'Summary'
                                    )
                                "
                            />

                            <validate-similarity
                                :source="blogpost.summary"
                                :target="newsArticleSummary"
                                message="Summary should be different from the News Article summary"
                            />
                        </amp-validation>
                    </template>
                </amp-row>

                <!-- Media -->
                <amp-row>
                    <template #input>
                        <div class="mb-1">Media</div>
                        <div class="pb-8">
                            <media-preview-list
                                :media-resources="blogpost.media_resources"
                                :user-id="blogpost.user_id"
                                class="pa-1"
                                module-title="Blog Post"
                                @mediaFiles="onMediaFilesUpdate"
                            />

                            <validation-provider
                                ref="media_resources"
                                name="media_resources"
                                slim
                                rules="required|imageMediaResourceLength:1"
                                :skip-if-empty="false"
                            >
                                <input
                                    v-model="blogpost.media_resources"
                                    type="hidden"
                                />
                            </validation-provider>
                        </div>
                    </template>
                    <template #validation>
                        <amp-validation :input="$refs.media_resources">
                            <a-alert
                                :type="
                                    blogpost.media_resources?.length
                                        ? 'success'
                                        : 'error'
                                "
                            >
                                Please provide at least one image
                            </a-alert>
                        </amp-validation>
                    </template>
                </amp-row>

                <!-- Content -->
                <amp-row>
                    <template #input>
                        <a-content-editor
                            v-model="blogpost.content"
                            label="Content"
                            rules="required"
                            observed
                            :no-first-person="false"
                            :formats="formats"
                            :toolbar="toolbar"
                        />
                    </template>

                    <template #validation>
                        <a-alert v-if="hasMSNCredits" type="info">
                            To maintain uniformity, uphold high-quality
                            standards, and ensure compliance with MSN criteria;
                            please refer to the following
                            <router-link to="/guidelines/msn" target="_blank">
                                <b>guidelines</b>
                            </router-link>
                            while creating your blog posts.
                        </a-alert>

                        <content-editor-validations
                            :content="blogpost.content"
                            rules="wordCountBetween:300,3000|linkCount:html"
                        />
                    </template>
                </amp-row>

                <amp-row-last />
            </template>
        </a-form>

        <generate-content-warning
            v-if="isLongFormEditor"
            id="confirm-generate-outline"
            @confirm="generateOutline"
        >
            <template #warning>
                This action will delete the current outline and content.
                Generating a new outline may take some time. Are you sure you
                want to proceed?
            </template>
        </generate-content-warning>

        <generate-content-warning
            v-if="isLongFormEditor"
            id="confirm-generate-content"
            @confirm="generateContent"
        >
            <template #warning>
                This action will delete the current content. Generating new
                content may take some time. Are you sure you want to proceed?
            </template>
        </generate-content-warning>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { NavigationGuardNext, Route } from 'vue-router';
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

import { Endpoint, WordsCounter, ValidateLinksReachability } from '@/mixins';
import { InjectReactive, Watch } from '@/utils/decorators';
import { areMediaResourcesEqual } from '@/utils/helpers';

import { AAlert } from '@/components/AAlert';
import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { AContentEditor } from '@/components/AForm/Inputs/AContentEditor';
import { ASelectInput } from '@/components/AForm/Inputs/ASelectInput';
import { ContentEditorValidations } from '@/components/ContentEditor';
import { MediaPreviewList } from '@/components/Media';
import { ValidateSimilarity } from '@/components/ValidateSimilarity';
import { AOutlineEditor } from '@/components/AOutlineEditor';
import { AmpExpansionPanel } from '@/components/AmpModule';
import { AmpLoadingCard } from '@/components/AmpModule';
import {
    AmpRow,
    AmpRowFirst,
    AmpRowLast,
    AmpValidation
} from '@/components/AmpModule/AmpPage';
import { BlogPost as IBlogPost } from '@/types/BlogPost';
import type { AmpModules, Announcement } from '@/types/Announcement';
import type { FormMediaResource } from '@/types/Media';
import type { ModuleLink } from '@/types/ModuleLink';
import type { MediaResource } from '@/types/MediaResource';
import { ContentSource } from '@/types/ContentSource';
import GenerateContentWarning from './GenerateContentWarning.vue';

Component.registerHooks(['beforeRouteLeave']);

@Component({
    components: {
        AForm,
        ATextInput,
        AmpLoadingCard,
        AmpRow,
        AmpRowFirst,
        AmpRowLast,
        AmpExpansionPanel,
        AmpValidation,
        MediaPreviewList,
        AContentEditor,
        AAlert,
        ContentEditorValidations,
        ValidateSimilarity,
        ASelectInput,
        AOutlineEditor,
        GenerateContentWarning
    },
    computed: {
        ...mapGetters('user', ['isEditor', 'isAdmin'])
    }
})
export default class BlogPost extends mixins(
    Endpoint,
    WordsCounter,
    ValidateLinksReachability
) {
    hasMSNCredits = false;

    contentSources: Array<ContentSource> = [];
    isLongFormEditor: boolean = true;
    isEditor!: boolean;
    isAdmin!: boolean;

    $refs!: {
        form: InstanceType<typeof AForm>;
        headline: InstanceType<typeof ATextInput>;
        summary: InstanceType<typeof ATextInput>;
        media_resources: InstanceType<typeof ValidationProvider>;
    };

    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    @InjectReactive({
        from: 'isModuleBusy',
        default() {
            return false;
        }
    })
    isModuleBusy!: boolean;

    @Watch('modules')
    onModulesChanged() {
        this.onMounted();
    }

    @Watch('blogpost.media_resources', { deep: true })
    onMediaResourcesChange(a: MediaResource[], b: MediaResource[]) {
        if (this.isReadyForChanges && !areMediaResourcesEqual(a, b)) {
            this.$refs.media_resources.setFlags({
                dirty: true,
                changed: true,
                touched: true
            });
        }
    }

    blogpost: Partial<IBlogPost> = {
        media_resources: []
    };

    isSaving = false;
    isReadyForChanges = false;

    endpoint = '/blog_posts/edit';

    link: ModuleLink[] = [
        {
            type: 'primary',
            label: 'Review',
            to: this.reviewLink
        }
    ];

    formats: Array<string> = [
        'link',
        'firstPersonError',
        'bold',
        'header',
        'italic',
        'underline',
        'list'
    ];

    toolbar: Array<Array<string | object>> = [
        ['link'],
        ['bold', 'italic', 'underline'],
        [{ header: [0, 1, 2, 3, 4, 5, 6] }],
        [{ list: 'ordered' }, { list: 'bullet' }]
    ];

    get moduleId() {
        return this.modules?.blog_post_id;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.moduleId].join('/');
    }

    get newsArticleHeadline() {
        return this.blogpost.announcement?.press_release.headline;
    }

    get newsArticleSummary() {
        return this.blogpost.announcement?.press_release.summary;
    }

    get publishIssues() {
        return this.$store.getters['broadcast/subscribe'](
            `${this.announcementId}-publish-blog-post`
        );
    }

    get reviewLink() {
        return `/announcements/review/${this.announcementId}/blog-post`;
    }

    get shouldShowOutlineEditor() {
        return this.blogpost?.outline?.length;
    }

    get showRegularEditor() {
        return this.blogpost?.content?.length;
    }

    showContentSources = false;
    showOutline = false;
    generatingString = 'Checking in..';

    keepRegularEditorOpen = false;
    @Watch('keepRegularEditorOpen')
    onKeepRegularEditorOpen(show: boolean) {
        if (show) {
            this.showContentSources = false;
            this.$emit('links', this.link);
        }
    }

    callingGenerativeApi = false;
    @Watch('callingGenerativeApi')
    onGeneratingLongFormPostChange(calling: boolean) {
        if (calling) {
            this.showContentSources = false;
            this.showOutline = false;
        }
    }

    @Watch('contentSources')
    onLinkChange(contentSources: Array<ContentSource>) {
        this.validateLinksReachability(
            contentSources.map(item => item.url).join(', ')
        );
    }

    get isInitialized() {
        return Boolean(this.blogpost.id);
    }

    onMounted() {
        if (this.moduleId) {
            this.setPrePublishHook();
            this.load();
        } else {
            this.askToCreate();
        }
    }

    setPrePublishHook(isSet = true) {
        this.$emit('pre-publish', isSet ? this.prePublish.bind(this) : null);
    }

    askToCreate() {
        if (this.modules && !this.moduleId) {
            this.$emit('create', this.endpoint);
        }
    }

    onData(data: {
        blogPost: IBlogPost;
        hasMSNCredits: boolean;
        isLongFormEditor: boolean;
        meta: { success: boolean };
        generation: {
            finished: boolean;
            error: boolean;
            step: string;
        } | null;
    }) {
        if (data.blogPost) {
            this.isLongFormEditor = data.isLongFormEditor;
            if (data.blogPost.content.length > 0 || !this.isLongFormEditor) {
                this.keepRegularEditorOpen = true;
            }

            const commited = this.commit(data);

            if (data.generation) {
                this.checkGenerativeApiData(data.generation);
            }

            return commited;
        } else {
            this.review();
        }
    }

    checkGenerativeApiData(data: {
        finished: boolean;
        error: boolean;
        step: string;
    }) {
        this.callingGenerativeApi = !data.finished;
        if (!this.callingGenerativeApi) {
            this.$emit('links', this.link);
        } else {
            this.$emit('links', []);
            this.generatingString = `Generating ${data.step}`;
            if (data.step == 'outline') {
                this.showOutline = true;
            }
        }

        if (data.error) {
            this.$store.dispatch(
                'notification/error',
                `Unable to generate ${data.step}. Please try again a few minutes or contact support.`
            );
        }

        if (this.callingGenerativeApi) {
            this.setAutoCheckStatusInterval();
        } else {
            this.clearAutoCheckStatusInterval();
        }
    }

    async commit(data: {
        blogPost?: IBlogPost;
        hasMSNCredits: boolean;
        meta: { success: boolean };
    }) {
        if (data.blogPost) {
            this.softCommit(data.blogPost);
            this.hasMSNCredits = data.hasMSNCredits;
            this.setContentSources();
            this.protectRoute();
            this.emitLinks();
        }
        await this.setSaved();
        this.isReadyForChanges = true;
        return data;
    }

    setActiveEditorSections() {
        if (this.isLongFormEditor && !this.blogpost.outline?.length) {
            this.showContentSources = true;
            return;
        }

        if (
            this.isLongFormEditor &&
            this.blogpost.outline?.length &&
            !this.blogpost.content?.length
        ) {
            this.showOutline = true;
        }
    }

    softCommit(data: IBlogPost) {
        if (this.isInitialized) {
            const softProperties: Array<keyof IBlogPost> = [
                'is_editable',
                'is_live',
                'is_publishable',
                'status',
                'status_string'
            ];

            // outline/content update when expecting API response
            if (this.callingGenerativeApi) {
                softProperties.push('outline');
                softProperties.push('content');
                softProperties.push('summary');
            }

            (Object.keys(data) as Array<keyof IBlogPost>)
                .filter(key => softProperties.includes(key))
                .forEach(key => {
                    this.$set(this.blogpost, key, data[key]);
                });
        } else {
            this.blogpost = data;
            this.setActiveEditorSections();
        }
    }

    getDataToSave() {
        return {
            id: this.blogpost.id,
            headline: this.blogpost.headline,
            summary: this.blogpost.summary,
            media_resources: this.blogpost.media_resources,
            content: this.blogpost.content,
            outline: this.blogpost.outline
        };
    }

    setContentSources() {
        if (this.contentSources.length) {
            return;
        }
        const { content_sources } = this.blogpost.announcement as Announcement;
        if (content_sources.length) {
            this.contentSources = content_sources;
        } else if (this.blogpost.announcement_id) {
            this.contentSources = [
                {
                    url: '',
                    announcement_id: this.blogpost.announcement_id,
                    id: null,
                    status: null,
                    title: null,
                    content: null
                },
                {
                    url: '',
                    announcement_id: this.blogpost.announcement_id,
                    id: null,
                    status: null,
                    title: null,
                    content: null
                }
            ];
        }
    }

    updateFieldsForContentGeneration() {
        this.blogpost.content = '';
        this.keepRegularEditorOpen = false;
    }

    confirmGenerateOutline() {
        if (this.blogpost?.outline?.length) {
            this.$store.dispatch('modal/open', 'confirm-generate-outline');
            return;
        }
        this.generateOutline();
    }

    confirmGenerateContent() {
        if (this.blogpost?.content?.length) {
            this.$store.dispatch('modal/open', 'confirm-generate-content');
            return;
        }
        this.generateContent();
    }

    updateContentSources() {
        return this.$http
            .post(`/content_sources/add/${this.blogpost.announcement_id}`, {
                content_sources: this.contentSources
            })
            .then(({ data }) => {
                return data.meta.success;
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    processContentSources() {
        this.generatingString = 'Fetching Background Info';
        this.callingGenerativeApi = true;
        this.$emit('links', []);

        return this.updateContentSources().then(success => {
            if (!success) {
                this.$store.dispatch(
                    'notification/error',
                    `Unable to save and process background info. Please try a different URL or try again in a few minutes.`
                );
                this.showContentSources = true;
                this.callingGenerativeApi = false;
            }

            return success;
        });
    }

    async generateOutline() {
        this.updateFieldsForContentGeneration();
        this.generatingString = 'Generating Outline';
        this.$nextTick(async () => {
            const valid = await this.revalidate();
            if (!valid) {
                return;
            }
            const contentSourcesProcessed = await this.processContentSources();
            if (!contentSourcesProcessed) {
                return;
            }
            await this.callGeneration(
                `/blog_posts/generate_outline/${this.moduleId}`
            );
        });
    }

    async generateContent() {
        this.generatingString = 'Generating Content';
        this.updateFieldsForContentGeneration();
        this.$nextTick(async () => {
            const valid = await this.revalidate();
            if (!valid) {
                return;
            }
            this.callGeneration(
                `/blog_posts/generate_content/${this.moduleId}`
            );
        });
    }

    async callGeneration(url: string) {
        return this.save(false).then(() => {
            this.callingGenerativeApi = true;
            this.$emit('links', []);
            this.$http
                .get(url)
                .then(({ data }) => {
                    if (!data?.data.success) {
                        this.$store.dispatch(
                            'notification/error',
                            'Unable to continue generation. Please try again or contact support'
                        );
                    }
                    this.setAutoCheckStatusInterval();
                })
                .catch(error => {
                    if (!error.isIntercepted) {
                        this.$store.dispatch('notification/error', error);
                    }
                });
        });
    }

    autoCheckStatusInterval: ReturnType<typeof setInterval> | null = null;

    setAutoCheckStatusInterval() {
        this.clearAutoCheckStatusInterval();
        this.autoCheckStatusInterval = setInterval(this.load.bind(this), 5000);
    }

    clearAutoCheckStatusInterval() {
        if (this.autoCheckStatusInterval) {
            clearInterval(this.autoCheckStatusInterval);
        }
    }

    save(foreground = true) {
        this.setSaving();

        return this.setData()
            .then(() => {
                if (foreground) {
                    this.onSave();
                }
            })
            .catch(() => {
                if (foreground) {
                    this.notifyError();
                }
            })
            .finally(this.setSaving.bind(this, false));
    }

    async autoSave() {
        return this.setData().catch(error => {
            if (!error.isIntercepted) {
                this.$store.dispatch('notification/error', error);
            }
        });
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }

    async setSaved() {
        if (this.$refs.form) {
            return this.$refs.form.reset();
        }
    }

    async setData() {
        return this.$http
            .post(this.sourceUrl, this.getDataToSave())
            .then(({ data }) => data)
            .then(({ data }) => {
                if (!data?.meta?.success) {
                    throw new Error(
                        'Unable to save Blog Post. Please check the form for errors.'
                    );
                }

                return data;
            })
            .then(data => this.commit(data));
    }

    async onSave() {
        const isValid = await this.revalidate();

        if (isValid) {
            this.review();
        }
    }

    async revalidate() {
        // reset existing errors
        this.$store.dispatch(
            'broadcast/reset',
            `${this.announcementId}-publish-blog-post`
        );

        const isValid = await this.$refs.form.validate();

        if (!isValid) {
            this.notifyInvalid();
        }

        return isValid;
    }

    review() {
        this.$router.push(this.reviewLink);
    }

    beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        this.setPrePublishHook(false);

        this.clearAutoCheckStatusInterval();
        if (this.$refs.form.isDirty || this.$refs.form.hasChanged) {
            return this.save(false).then(() => {
                next();
            });
        } else {
            return next();
        }
    }

    protectRoute() {
        if (!this.blogpost.is_editable) {
            this.review();
        }
    }

    emitLinks() {
        if (!this.callingGenerativeApi) {
            this.$emit('links', this.link);
        }
    }

    prePublish() {
        if (!this.$refs.form?.isDirty) {
            return this.revalidate();
        }

        this.setSaving();

        return this.setData()
            .then(() => this.revalidate())
            .catch(() => {
                this.notifyError();

                return false;
            })
            .finally(this.setSaving.bind(this, false));
    }

    notifyInvalid() {
        this.$store.dispatch('notification/info', 'Please review for errors.');
    }

    notifyError() {
        this.$store.dispatch(
            'notification/error',
            'Unable to save Blog Post. Please check the form for errors.'
        );
    }

    onMediaFilesUpdate(resources: FormMediaResource[]) {
        this.blogpost.media_resources = [...resources];

        this.$refs.media_resources.setFlags({
            dirty: true,
            changed: true,
            touched: true
        });
    }
}
</script>
